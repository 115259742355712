import {
  createRouter,
  createWebHistory
} from 'vue-router'

// Définition des routes publiques
const publicRoutes = [{
  path: '/',
    name: 'indexPage',
    component: () => import('@/views/indexPage.vue'),
},


];

const routes = [
  ...publicRoutes,
];
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router